import React, { useEffect, useState } from "react";
import { TravellersTitle } from "./styles";
import {
      FlightDetails,
      TravellersForm,
} from "../../../../frontend/checkout/styles";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MealList from "../MealList";
import GstForm from "../GstForm";
import SeatMapList from "../SeatMapList";
import TravellerDetailList from "../TravellerDetailList";
import ContactInfo from "../ContactInfo";
import FlightFooter from "../FlightFooter";

const tripAirlineInfo = (tripInfos, setDestinationDetails) => {
      const airlineDetails = [];

      tripInfos?.forEach((item) => {
            item?.sI?.forEach((airlineInfoItem) => {
                  airlineDetails.push(airlineInfoItem);
            });
      });

      setDestinationDetails(airlineDetails);
};

const serviceTemplate = {
      Meal: {
            name: "",
            code: "",
            amount: 0,
      },
      Baggage: {
            name: "",
            code: "",
            amount: 0,
      },
      OtherService: {
            name: "",
            code: "",
            amount: 0,
      },
      Seat: {
            name: "",
            code: "",
            amount: 0,
            status: false,
      },
};

const TravellersDetailBox = ({
      tripInfos,
      marginBottom,
      searchQuery,
      options,
      setOptions,
      currentOption,
      setCurrentOption,
      reviewId,
      bookingId,
      conditions,
      serviceList,
      setServiceList,
      destinationDetails,
      setDestinationDetails,
      totalAmount,
      setTotalAmount,
}) => {
      const [loading, setLoading] = useState(false);
      const [loadingBack, setLoadingBack] = useState(false);
      const navigate = useNavigate();
      const previousSearch = localStorage.getItem("previousSearch");

      const [validated, setValidated] = useState(false);

      const [travellers, setTravellers] = useState([]);
      const [counts, setCounts] = useState({
            adults: searchQuery?.paxInfo?.ADULT,
            children: searchQuery?.paxInfo?.CHILD,
            infants: searchQuery?.paxInfo?.INFANT,
      });
      const [contactDetails, setContactDetails] = useState({
            countryCode: "+91",
            emailAddress: "",
            phoneNumber: "",
      });

      const [gstDetails, setGstDetails] = useState({
            gstStatus: false,
            registrationNumber: "",
            companyName: "",
            companyEmail: "",
            companyAddress: "",
            companyPhone: "",
      });

      const handleInputChange = (index, event, type, data, inputField) => {
            const updatedTravellers = [...travellers];

            if (type === "SELECT") {
                  updatedTravellers[index] = {
                        ...updatedTravellers[index],
                        [inputField]: data,
                  };
            } else {
                  const { name, value } = event.target;

                  updatedTravellers[index] = {
                        ...updatedTravellers[index],
                        [name]: value,
                  };
            }

            setTravellers(updatedTravellers);
      };

      const updateTravellers = () => {
            const { adults, children, infants } = counts;
            const totalTravellers = adults + children + infants;

            const newTravellers = Array(totalTravellers)
                  .fill()
                  .map((_, index) => {
                        let pt = "";
                        let typeIndex = 0;

                        if (index < adults) {
                              typeIndex = index + 1;
                              pt = `ADULT-${typeIndex}`;
                        } else if (index < adults + children) {
                              typeIndex = index - adults + 1;
                              pt = `CHILD-${typeIndex}`;
                        } else {
                              typeIndex = index - adults - children + 1;
                              pt = `INFANT-${typeIndex}`;
                        }

                        return {
                              title: "",
                              firstName: "",
                              lastName: "",
                              ptName: pt,
                              pt:
                                    index < adults
                                          ? "ADULT"
                                          : index < adults + children
                                          ? "CHILD"
                                          : "INFANT",
                              dateOfBirth: "",
                              nationality: "IN",
                              passportNumber: "",
                              passportExpiry: "",
                              frequentStatus: false,
                              frequentAirline: "",
                              frequentNumber: "",
                        };
                  });

            setTravellers(newTravellers);
      };

      const handleTitle = (value, type, index, event) => {
            if (type === "title") {
                  handleInputChange(index, event, "SELECT", value, type);
            }
            if (type === "country") {
                  handleInputChange(index, event, "SELECT", value, type);
            }
            if (type === "nationality") {
                  handleInputChange(index, event, "SELECT", value, type);
            }
      };

      useEffect(() => {
            tripAirlineInfo(tripInfos, setDestinationDetails);
            updateTravellers();
      }, []);

      useEffect(() => {
            const services = destinationDetails.reduce((acc, destination) => {
                  acc[`${destination?.da?.code}-${destination?.aa?.code}`] =
                        travellers.reduce((innerAcc, passenger) => {
                              if (passenger?.pt !== "INFANT") {
                                    innerAcc[passenger?.ptName] = {
                                          ...serviceTemplate,
                                    };
                              }
                              return innerAcc;
                        }, {});
                  return acc;
            }, {});

            if (services) {
                  setServiceList(
                        JSON.parse(localStorage.getItem("services")) || services
                  );
            }
      }, [destinationDetails]);

      const handleBack = () => {
            setLoadingBack(true);
            setTimeout(() => {
                  setLoadingBack(false);
                  navigate(previousSearch);
            }, 3000);
      };

      const handleContactDetails = (event, type, data) => {
            if (type === "SELECT") {
                  setContactDetails((prevDetails) => ({
                        ...prevDetails,
                        ["countryCode"]: data,
                  }));
            } else {
                  const { name, value } = event.target;

                  setContactDetails((prevDetails) => ({
                        ...prevDetails,
                        [name]: value,
                  }));
            }
      };

      const handleGstDetails = (event) => {
            const { name, value } = event.target;

            setGstDetails((prevDetails) => ({
                  ...prevDetails,
                  [name]: value,
            }));
      };

      const handleSubmit = (event) => {
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                  event.preventDefault();
                  event.stopPropagation();
            }

            setValidated(true);
            setLoading(true);

            event.preventDefault();

            if (gstDetails?.gstStatus) {
                  localStorage.setItem(
                        "gstDetails",
                        JSON.stringify(gstDetails)
                  );
            }
            localStorage.setItem(
                  "contactDetails",
                  JSON.stringify(contactDetails)
            );
            localStorage.setItem("totalAmount", totalAmount);
            localStorage.setItem("travellers", JSON.stringify(travellers));
            localStorage.setItem("services", JSON.stringify(serviceList));

            let optionItem = currentOption.index + 1;
            setTimeout(() => {
                  setLoading(false);
                  const updateOptions = options.map((item, i) => {
                        if (i <= optionItem) {
                              return { ...item, status: true };
                        }
                        return { ...item, status: false };
                  });
                  setOptions(updateOptions);
                  setCurrentOption({
                        id: updateOptions[optionItem].id,
                        index: optionItem,
                  });
                  navigate(
                        `/flight/${updateOptions[optionItem].id}/${reviewId}`
                  );
            }, 3000);
      };

      useEffect(() => {
            if (reviewId === localStorage.getItem("reviewId")) {
                  if (localStorage.getItem("travellers")) {
                        setTravellers(
                              JSON.parse(localStorage.getItem("travellers"))
                        );
                  }
                  if (localStorage.getItem("gstDetails")) {
                        setGstDetails(
                              JSON.parse(localStorage.getItem("gstDetails"))
                        );
                  }
                  if (localStorage.getItem("contactDetails")) {
                        setContactDetails(
                              JSON.parse(localStorage.getItem("contactDetails"))
                        );
                  }
                  if (localStorage.getItem("services")) {
                        setServiceList(
                              JSON.parse(localStorage.getItem("services"))
                        );
                  }
            } else {
                  localStorage.removeItem("contactDetails");
                  localStorage.removeItem("gstDetails");
                  localStorage.removeItem("travellers");
                  localStorage.removeItem("services");
                  localStorage.removeItem("totalAmount");
            }
      }, []);

      return (
            <>
                  <Form method="post" onSubmit={handleSubmit}>
                        <TravellerDetailList
                              travellers={travellers}
                              handleInputChange={handleInputChange}
                              handleTitle={handleTitle}
                              searchQuery={searchQuery}
                              marginBottom={marginBottom}
                        />

                        <FlightDetails style={{ marginBottom }}>
                              <TravellersTitle className="mt-2 d-flex gap-2">
                                    <h3>Baggage, Meal & Other Service</h3>
                              </TravellersTitle>
                              <TravellersForm padding="0" className="mt-3">
                                    {serviceList &&
                                          Object.entries(serviceList)?.map(
                                                ([destination, passengers]) => (
                                                      <Row className="g-3">
                                                            <Col lg={12}>
                                                                  <MealList
                                                                        passengers={
                                                                              passengers
                                                                        }
                                                                        destination={
                                                                              destination
                                                                        }
                                                                        destinationDetails={
                                                                              destinationDetails
                                                                        }
                                                                        setServiceList={
                                                                              setServiceList
                                                                        }
                                                                        serviceList={
                                                                              serviceList
                                                                        }
                                                                        totalAmount={
                                                                              totalAmount
                                                                        }
                                                                        setTotalAmount={
                                                                              setTotalAmount
                                                                        }
                                                                  />
                                                            </Col>
                                                      </Row>
                                                )
                                          )}
                              </TravellersForm>
                        </FlightDetails>

                        {conditions?.isa && serviceList && (
                              <FlightDetails style={{ marginBottom }}>
                                    <TravellersTitle className="mb-2 d-flex gap-2">
                                          <h3>Seats (Optional)</h3>
                                    </TravellersTitle>
                                    <TravellersForm padding="0">
                                          {destinationDetails?.map((item) => (
                                                <SeatMapList
                                                      bookingId={bookingId}
                                                      tripInfos={item}
                                                      serviceList={serviceList}
                                                      setServiceList={
                                                            setServiceList
                                                      }
                                                      serviceType="Seat"
                                                      totalAmount={totalAmount}
                                                      setTotalAmount={
                                                            setTotalAmount
                                                      }
                                                />
                                          ))}
                                    </TravellersForm>
                              </FlightDetails>
                        )}

                        <FlightDetails style={{ marginBottom }}>
                              <ContactInfo
                                    contactDetails={contactDetails}
                                    handleContactDetails={handleContactDetails}
                              />
                        </FlightDetails>

                        <FlightDetails style={{ marginBottom }}>
                              <GstForm
                                    handleGstDetails={handleGstDetails}
                                    gstDetails={gstDetails}
                              />
                        </FlightDetails>
                        <FlightFooter
                              loading={loading}
                              handleBack={handleBack}
                              loadingBack={loadingBack}
                        />
                  </Form>
            </>
      );
};

export default TravellersDetailBox;
