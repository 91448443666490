import React, { useContext } from "react";
import {
      BreadcrumbContainer,
      DashboardBreadcrumb,
      DashboardContainer,
      DashboardContent,
      DashboardSidebar,
      ProfileCard,
      ProfileImage,
      ProfileName,
} from "./style";
import { Breadcrumb } from "react-bootstrap";
import { MenuList } from "./menus";
import UserContext from "../../context/UserContext";

const DashboardLayout = ({ title, children }) => {
      const profileCtx = useContext(UserContext);

      return (
            <>
                  <DashboardContainer>
                        <DashboardBreadcrumb>
                              <BreadcrumbContainer>
                                    <Breadcrumb>
                                          <Breadcrumb.Item href="#">
                                                Home
                                          </Breadcrumb.Item>
                                          <Breadcrumb.Item href="/my-account">
                                                My Account
                                          </Breadcrumb.Item>
                                    </Breadcrumb>
                                    <h2>{title}</h2>
                              </BreadcrumbContainer>
                        </DashboardBreadcrumb>
                        <DashboardContent>
                              <DashboardSidebar>
                                    <ProfileCard>
                                          <ProfileImage>
                                                <img
                                                      src={
                                                            profileCtx?.profile
                                                                  ?.profile ||
                                                            "/images/no-image-icon.png"
                                                      }
                                                      alt=""
                                                />
                                          </ProfileImage>
                                          <ProfileName>
                                                {profileCtx?.profile?.name}
                                          </ProfileName>
                                    </ProfileCard>
                                    <MenuList />
                              </DashboardSidebar>
                              {children}
                        </DashboardContent>
                  </DashboardContainer>
            </>
      );
};

export default DashboardLayout;
