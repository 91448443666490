import React from "react";
import DashboardLayout from "../layouts";

const AccountStatement = () => {
      return (
            <>
                  <DashboardLayout title="Account Statement"></DashboardLayout>
            </>
      );
};

export default AccountStatement;
