import React, { useState } from "react";
import { LoginFormSection, LoginInput } from "../styles";
import { Col, FormControl, Row } from "react-bootstrap";
import { LockIcon, UserIcon } from "../../../icons";
import NonVisibilityIcon from "../../../icons/NonVisibilityIcon";
import CommonButton from "../../../components/Common/CommonButton";
import toast from "react-hot-toast";
import axios from "axios";

const LoginForm = ({ loginFormRef, activeForm }) => {
      const [redirect, setRedirect] = useState(false);

      const [type, setType] = useState("text");
      const [phoneNumber, setPhoneNumber] = useState("");
      const [password, setPassword] = useState("");
      const [loading, setLoading] = useState(false);

      const handleType = () => {
            setType("password");
      };

      async function handleForm(event) {
            event.preventDefault();
            setLoading(true);
            if (phoneNumber === "") {
                  toast.error("Phone Number field has been required.");
            }
            if (password === "") {
                  toast.error("Password field has been required.");
            }

            await axios
                  .post(`${process.env.REACT_APP_SECRET_KEY}/api/agent/login`, {
                        phone_number: phoneNumber,
                        password: password,
                  })
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.result === "success") {
                                    toast.success(
                                          "You are successfully Loggedin"
                                    );
                                    localStorage.setItem("isLoggedIn", true);
                                    localStorage.setItem(
                                          "token",
                                          JSON.stringify(
                                                response.data.token.original
                                                      .token
                                          )
                                    );
                                    localStorage.setItem(
                                          "tokenExpiration",
                                          new Date(
                                                new Date().getTime() +
                                                      response.data.token
                                                            .original
                                                            .token_validity *
                                                            1000
                                          )
                                    );
                                    setTimeout(() => {
                                          setRedirect(true);
                                    }, 2000);
                              }
                              if (response.data.result === "error") {
                                    if (response.data.message) {
                                          toast.error(response.data.message);
                                    }
                                    if (response.data.message.phone_number) {
                                          toast.error(
                                                response.data.message
                                                      .phone_number
                                          );
                                    }
                                    if (response.data.message.password) {
                                          toast.error(
                                                response.data.message.password
                                          );
                                    }
                              }
                              setLoading(false);
                        }, 3000);
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              setLoading(false);
                              console.log(error.message);
                        }, 3000);
                  });
      }

      if (redirect) {
            window.location.reload();
      }

      return (
            <>
                  <LoginFormSection
                        onSubmit={handleForm}
                        ref={loginFormRef}
                        style={{
                              transform:
                                    activeForm === "login"
                                          ? "translateX(0px)"
                                          : "translateX(-420px)",
                        }}
                  >
                        <Row>
                              <div className="col-lg-12">
                                    <LoginInput className="mb-4 ps-2">
                                          <UserIcon />
                                          <input
                                                type="text"
                                                onChange={(event) =>
                                                      setPhoneNumber(
                                                            event.target.value
                                                      )
                                                }
                                                onClick={handleType}
                                                onBlur={handleType}
                                                placeholder="Enter Mobile Number "
                                          />
                                    </LoginInput>
                              </div>
                              <div className="col-lg-12">
                                    <LoginInput className="mb-3 px-2">
                                          <LockIcon />
                                          <FormControl
                                                type={
                                                      type ? "password" : "text"
                                                }
                                                onChange={(event) =>
                                                      setPassword(
                                                            event.target.value
                                                      )
                                                }
                                                onClick={handleType}
                                                onBlur={handleType}
                                                placeholder="Password"
                                          />
                                          <NonVisibilityIcon
                                                show={type}
                                                setShowPassword={setType}
                                          />
                                    </LoginInput>
                              </div>

                              {/* <Col lg={12} className="mb-3">
                                    <LoginLink to="/forget-password">
                                          Reset Your Password
                                    </LoginLink>
                              </Col> */}
                              <Col
                                    lg={12}
                                    className="p-0 d-flex align-items-center justify-content-center"
                              >
                                    <CommonButton
                                          title={"Login"}
                                          button={true}
                                          type={"submit"}
                                          loading={loading}
                                    />
                              </Col>
                        </Row>
                  </LoginFormSection>
            </>
      );
};

export default LoginForm;
