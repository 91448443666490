import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const FlightModal = styled(Modal)`
      .btn-close {
            &:focus {
                  box-shadow: none;
            }
      }
`;

export const FlightPriceItem = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      input {
            width: 120px;
      }

      .grand__total {
            font-weight: 600;
      }
`;
