import styled from "styled-components";

export const FlightReviewTable = styled.div`
      display: flex;
      /* justify-content: space-between; */
      align-items: start;
      margin-bottom: ${(props) => props.marginBottom};
      margin-top: ${(props) => props.marginTop};
      font-size: 14px;
      padding: 10px 0;
      border-top: ${(props) =>
            props.border && "1px dashed" + props.theme.primary};

      .flight__table-header {
            color: #1a1a1a;
            font-size: 19px;
            font-weight: 400;
            line-height: 28.57px;
            text-align: left;
            display: flex;
            align-items: center;
            gap: 8px;
      }

      div {
            font-size: 16px;
            /* &:nth-child(1) {
                  width: 5%;
            } */

            /* &:nth-child(2) {
                  width: 25%;
            } */

            /* &:nth-child(3) {
                  width: 18%;
            } */

            /* &:nth-child(4) {
                  width: 40%;
            } */
      }

      li {
            list-style: none;

            strong,
            span {
                  font-size: 15px;
            }
      }
`;

export const FlightTableHeader = styled.div`
      color: #1a1a1a;
      font-size: 19px;
      font-weight: 400;
      line-height: 28.57px;
      text-align: left;
      display: flex;
      align-items: center;
      gap: 8px;
      width: ${(props) => props.width};
`;

export const FlightReviewContent = styled.div``;

export const FlightReviewHeader = styled.div`
      font-size: 14px;
      background: #224082;
      display: inline-block;
      color: #fff;
      border-bottom-right-radius: 15px;
      padding: 4px 16px;
      text-transform: uppercase;
`;

export const FlightReviewInfo = styled.div`
      padding: 10px;

      ul {
            margin: 0;
      }
`;
