import styled from "styled-components";

export const MenuItemContainer = styled.li`
      font-size: 17px;
      text-transform: capitalize;
      /* padding: 2rem 0; */

      &:hover {
            a {
                  color: ${(props) => props.theme.secondary};
            }
      }

      a {
            color: ${(props) => (props.active ? "#fff" : "#222")};
            transition: all 0.3s ease-in-out;
            background: transparent;
            padding: 10px 20px;
            border-radius: 50rem;

            &:hover {
                  background: #f0f0f0;
                  padding: 10px 20px;
                  border-radius: 50rem;
                  color: #222 !important;
            }
      }
`;
