import { Accordion } from "react-bootstrap";
import styled from "styled-components";

export const PassengerAccordion = styled(Accordion)`
      border-bottom: ${(props) =>
            props.border ? props.border : "1px solid #e5e3e3"};
      border-top: ${(props) =>
            props.border ? props.border : "1px solid #e5e3e3"};

      .total {
            padding: 0 0px;
      }

      .accordion-button {
            padding: 7px 0px;
            font-size: 18px;

            &:focus,
            &:not(.collapsed) {
                  box-shadow: none;
                  background-color: transparent;
            }
      }

      .accordion-body {
            padding: 0;
      }

      .price__item {
            display: flex;
      }
`;
