import React from "react";
import {
      FlightAirline,
      FlightAirlineTime,
      FlightAirlineTitle,
      FlightDetailAirline,
      FlightDetailCategory,
      FlightDetailContainer,
      FlightDetailInfo,
      FlightDetailTitle,
      FlightLayoverContainer,
      FlightLayoverContent,
      FlightLayoverLine,
      FlightLayoverTitle,
      FlightRefund,
} from "../../../Dashboard/FlightDetails/style";
import { FlightInfoLogo } from "../../../Dashboard/FlightInfo/styles";
import { format } from "date-fns";
import { BaggageIcon, TripIcon } from "../../../../icons";
import {
      convertDate,
      formatTime,
      calculateDuration,
      calculateTimeDifference,
} from "../../../../hooks/CurrentData";

const FlightDetailBox = ({ marginBottom, tripInfos }) => {
      return (
            <>
                  <FlightDetailContainer style={{ marginBottom }}>
                        <FlightDetailCategory>
                              Flight Details
                        </FlightDetailCategory>
                        {tripInfos?.map((info) => (
                              <FlightDetailInfo border={true}>
                                    {info?.sI?.map((item, index) => (
                                          <>
                                                {index === 0 && (
                                                      <FlightDetailTitle>
                                                            {item?.da?.city} -{" "}
                                                            {item?.aa?.city} |{" "}
                                                            <span>
                                                                  {convertDate(
                                                                        item?.dt
                                                                  )}
                                                            </span>
                                                      </FlightDetailTitle>
                                                )}
                                                <div>
                                                      <FlightDetailAirline>
                                                            <FlightAirline>
                                                                  <FlightInfoLogo>
                                                                        <img
                                                                              src={`/images/AirlinesLogo/${item?.fD?.aI?.code}.png`}
                                                                              alt={
                                                                                    item
                                                                                          ?.fD
                                                                                          ?.aI
                                                                                          ?.name
                                                                              }
                                                                        />
                                                                  </FlightInfoLogo>
                                                                  <FlightAirlineTitle>
                                                                        <div>
                                                                              {
                                                                                    item
                                                                                          ?.fD
                                                                                          ?.aI
                                                                                          ?.name
                                                                              }
                                                                        </div>
                                                                        <span>
                                                                              {
                                                                                    item
                                                                                          ?.fD
                                                                                          ?.aI
                                                                                          ?.code
                                                                              }{" "}
                                                                              {
                                                                                    item
                                                                                          ?.fD
                                                                                          ?.fN
                                                                              }
                                                                        </span>
                                                                        {/* <span>ECONOMY</span> */}
                                                                  </FlightAirlineTitle>
                                                            </FlightAirline>
                                                            <FlightAirlineTime>
                                                                  <div>
                                                                        {formatTime(
                                                                              item?.dt
                                                                        )}
                                                                  </div>
                                                                  <span>
                                                                        {
                                                                              item
                                                                                    ?.da
                                                                                    ?.city
                                                                        }{" "}
                                                                        (
                                                                        {
                                                                              item
                                                                                    ?.da
                                                                                    ?.code
                                                                        }
                                                                        )
                                                                  </span>
                                                                  <span>
                                                                        {convertDate(
                                                                              item?.dt
                                                                        )}
                                                                  </span>
                                                                  <span>
                                                                        {
                                                                              item
                                                                                    ?.da
                                                                                    ?.terminal
                                                                        }
                                                                  </span>
                                                            </FlightAirlineTime>
                                                            <FlightRefund>
                                                                  <div className="flight__duration">
                                                                        {`${
                                                                              calculateDuration(
                                                                                    item?.duration
                                                                              )
                                                                                    .hours
                                                                        }h ${
                                                                              calculateDuration(
                                                                                    item?.duration
                                                                              )
                                                                                    .minutes
                                                                        }m`}
                                                                  </div>
                                                                  <div className="line"></div>
                                                                  <div class="flight__refund">
                                                                        {tripInfos[0]
                                                                              ?.totalPriceList[0]
                                                                              ?.fd
                                                                              ?.ADULT
                                                                              ?.rT
                                                                              ? "REFUNDABLE"
                                                                              : "NON REFUNDABLE"}
                                                                  </div>
                                                            </FlightRefund>
                                                            <FlightAirlineTime>
                                                                  <div>
                                                                        {formatTime(
                                                                              item?.at
                                                                        )}
                                                                  </div>
                                                                  <span>
                                                                        {
                                                                              item
                                                                                    ?.aa
                                                                                    ?.city
                                                                        }{" "}
                                                                        (
                                                                        {
                                                                              item
                                                                                    ?.aa
                                                                                    ?.code
                                                                        }
                                                                        )
                                                                  </span>
                                                                  <span>
                                                                        {convertDate(
                                                                              item?.at
                                                                        )}
                                                                  </span>
                                                                  <span>
                                                                        {
                                                                              item
                                                                                    ?.aa
                                                                                    ?.terminal
                                                                        }
                                                                  </span>
                                                            </FlightAirlineTime>
                                                      </FlightDetailAirline>
                                                      <FlightDetailInfo fontSize="14px">
                                                            <TripIcon
                                                                  width="18px"
                                                                  height="18px"
                                                            />{" "}
                                                            Check-In Baggage:{" "}
                                                            {
                                                                  tripInfos[0]
                                                                        ?.totalPriceList[0]
                                                                        ?.fd
                                                                        ?.ADULT
                                                                        ?.bI?.iB
                                                            }
                                                            <BaggageIcon
                                                                  width="18px"
                                                                  height="18px"
                                                                  classes="ms-3"
                                                            />{" "}
                                                            Cabin Baggage:{" "}
                                                            {
                                                                  tripInfos[0]
                                                                        ?.totalPriceList[0]
                                                                        ?.fd
                                                                        ?.ADULT
                                                                        ?.bI?.cB
                                                            }
                                                      </FlightDetailInfo>
                                                </div>
                                                {tripInfos?.sI?.length > 1 &&
                                                      tripInfos?.sI.length !==
                                                            index + 1 && (
                                                            <FlightLayoverContainer className="my-4">
                                                                  <FlightLayoverLine />
                                                                  <FlightLayoverContent>
                                                                        <FlightLayoverTitle
                                                                              fontWeight="600"
                                                                              color="#224082"
                                                                        >
                                                                              Plane
                                                                              Change
                                                                        </FlightLayoverTitle>
                                                                        <FlightLayoverTitle
                                                                              fontSize="0.875rem"
                                                                              color="#555"
                                                                        >
                                                                              <span>
                                                                                    Layover
                                                                                    at
                                                                                    {` `}
                                                                                    {
                                                                                          item
                                                                                                ?.aa
                                                                                                ?.city
                                                                                    }

                                                                                    ,
                                                                                    {` ${
                                                                                          calculateTimeDifference(
                                                                                                item?.at,
                                                                                                tripInfos[0]
                                                                                                      ?.sI[
                                                                                                      index +
                                                                                                            1
                                                                                                ]
                                                                                                      ?.dt
                                                                                          )
                                                                                                .hours
                                                                                    }h ${
                                                                                          calculateTimeDifference(
                                                                                                item?.at,
                                                                                                tripInfos[0]
                                                                                                      ?.sI[
                                                                                                      index +
                                                                                                            1
                                                                                                ]
                                                                                                      ?.dt
                                                                                          )
                                                                                                .minutes
                                                                                    }m`}
                                                                              </span>
                                                                        </FlightLayoverTitle>
                                                                  </FlightLayoverContent>
                                                            </FlightLayoverContainer>
                                                      )}
                                          </>
                                    ))}
                              </FlightDetailInfo>
                        ))}
                  </FlightDetailContainer>
            </>
      );
};

export default FlightDetailBox;
