import React from "react";
import { NavLink } from "react-router-dom";
import { MenuButton, MenuIcon } from "./style";
import { Spinner } from "react-bootstrap";

const MenuItem = ({
      link,
      icon,
      title,
      classes,
      button,
      handleClick,
      type,
      loading,
}) => {
      return (
            <li>
                  {button ? (
                        <MenuButton
                              onClick={handleClick}
                              type={type}
                              className={classes}
                        >
                              {icon && <MenuIcon>{icon}</MenuIcon>}
                              <div className="menu__button-title">
                                    <div>{title}</div>
                                    <div>{loading && <Spinner />}</div>
                              </div>
                        </MenuButton>
                  ) : (
                        <NavLink to={link} className={classes}>
                              {icon && <MenuIcon>{icon}</MenuIcon>}
                              {title}
                        </NavLink>
                  )}
            </li>
      );
};

export default MenuItem;
