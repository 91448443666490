import React, { useEffect, useState } from "react";
import { DashboardContainer } from "../dashboard/styles";
import axios from "axios";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { FlightConfirmationDownload, FlightConfirmationHeader } from "./style";
import { Col, Row, Spinner } from "react-bootstrap";
import { convertAmount, convertFloat } from "../../hooks/CurrentData";
import { PriceSummary } from "../checkout/styles";
import PriceSummaryContent from "../../components/Frontend/checkout/PriceSummaryBox/PriceSummaryContent";
import FlightConfirmModal from "./FlightConfirmModal";
import { FlightReviewContent } from "../flight_reviews/style";
import FlightConfirmPassenger from "./FlightConfirmPassenger";
import FlightConfirmDetail from "./FlightConfirmDetail";
import { FlightDetailContainer } from "../../components/Dashboard/FlightDetails/style";

const FlightConfirmation = () => {
      const [loading, setLoading] = useState(false);
      const { bookingId } = useParams();
      const [order, setOrder] = useState([]);
      const [tripInfos, setTripInfos] = useState([]);
      const [totalPriceInfo, setTotalPriceInfo] = useState([]);
      const [travellerInfos, setTravellerInfos] = useState([]);

      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoading(true);

            const response = await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight/bookings/${bookingId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .catch((error) => {
                        toast.err(error.message);
                  });

            console.log(response);

            if (response?.data?.result === "success") {
                  await axios
                        .post(
                              `${process.env.REACT_APP_Flight_Api_Website}/oms/v1/booking-details`,
                              {
                                    bookingId:
                                          response?.data?.agent?.tj_booking_id,
                                    requirePaxPricing: true,
                              },
                              {
                                    headers: {
                                          apikey: process.env
                                                .REACT_APP_Flight_Api_Key,
                                    },
                              }
                        )
                        .then((response) => {
                              if (response.data.status?.success === true) {
                                    setOrder(response.data.order);
                                    setTripInfos(
                                          response.data.itemInfos?.AIR
                                                ?.tripInfos
                                    );
                                    setTotalPriceInfo(
                                          response.data.itemInfos?.AIR
                                                ?.totalPriceInfo
                                                ?.totalFareDetail
                                    );
                                    setTravellerInfos(
                                          response.data.itemInfos?.AIR
                                                ?.travellerInfos
                                    );
                              }
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
            }
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <DashboardContainer flexDirection="column" padding="120px">
                        {!loading ? (
                              <Row>
                                    <Col lg={8}>
                                          <FlightConfirmationDownload>
                                                <FlightConfirmationHeader>
                                                      <div className="confirmation__header-img">
                                                            <img
                                                                  src="/images/booking.png"
                                                                  alt=""
                                                            />
                                                      </div>
                                                      <div className="confirmation__header-title text-success">
                                                            Booking Success
                                                            <div className="confirmation__header-subtitle">
                                                                  Booking Id:{" "}
                                                                  <span>
                                                                        {
                                                                              bookingId
                                                                        }
                                                                  </span>
                                                            </div>
                                                      </div>
                                                </FlightConfirmationHeader>
                                                <div>
                                                      <FlightConfirmModal
                                                            totalPriceInfo={
                                                                  totalPriceInfo
                                                            }
                                                            bookingId={
                                                                  bookingId
                                                            }
                                                            order={order}
                                                            travellerInfos={
                                                                  travellerInfos
                                                            }
                                                            tripInfos={
                                                                  tripInfos
                                                            }
                                                      />
                                                </div>
                                          </FlightConfirmationDownload>
                                          <FlightConfirmDetail
                                                tripInfos={tripInfos}
                                          />
                                          <FlightDetailContainer padding="10px 30px">
                                                <FlightConfirmPassenger
                                                      travellerInfos={
                                                            travellerInfos
                                                      }
                                                      tripInfos={tripInfos}
                                                />
                                          </FlightDetailContainer>
                                    </Col>
                                    <Col lg={4}>
                                          <FlightReviewContent>
                                                <PriceSummary>
                                                      <div className="price__box mb-4">
                                                            <div className="price__heading">
                                                                  <div className="price__title w-75">
                                                                        Payment
                                                                        Summary
                                                                  </div>
                                                            </div>
                                                            <ul className="price__list list-inline mb-0 border-bottom">
                                                                  <li className="price__item d-flex align-items-center">
                                                                        <div className="price__item--title w-75">
                                                                              Base
                                                                              Fare
                                                                        </div>
                                                                        <div className="price__item--amount">
                                                                              ₹{" "}
                                                                              {convertAmount(
                                                                                    convertFloat(
                                                                                          totalPriceInfo
                                                                                                ?.fC
                                                                                                ?.BF
                                                                                    )
                                                                              )}
                                                                        </div>
                                                                  </li>
                                                            </ul>
                                                            <PriceSummaryContent
                                                                  title="Taxes & Fees"
                                                                  border="0px"
                                                                  totalServicesAmount={convertAmount(
                                                                        convertFloat(
                                                                              totalPriceInfo
                                                                                    ?.fC
                                                                                    ?.TAF
                                                                        )
                                                                  )}
                                                            >
                                                                  <ul className="price__list list-inline mb-0">
                                                                        {totalPriceInfo
                                                                              ?.afC
                                                                              ?.TAF
                                                                              ?.AGST && (
                                                                              <li className="price__item  d-flex align-items-center">
                                                                                    <div className="price__item--title w-75">
                                                                                          Airline
                                                                                          GST
                                                                                    </div>
                                                                                    <div className="price__item--amount">
                                                                                          ₹{" "}
                                                                                          {convertAmount(
                                                                                                convertFloat(
                                                                                                      totalPriceInfo
                                                                                                            ?.afC
                                                                                                            ?.TAF
                                                                                                            ?.AGST
                                                                                                )
                                                                                          )}
                                                                                    </div>
                                                                              </li>
                                                                        )}
                                                                        {totalPriceInfo
                                                                              ?.afC
                                                                              ?.TAF
                                                                              ?.OT && (
                                                                              <li className="price__item  d-flex align-items-center">
                                                                                    <div className="price__item--title w-75">
                                                                                          Other
                                                                                          Taxes
                                                                                    </div>
                                                                                    <div className="price__item--amount">
                                                                                          ₹{" "}
                                                                                          {convertAmount(
                                                                                                convertFloat(
                                                                                                      totalPriceInfo
                                                                                                            ?.afC
                                                                                                            ?.TAF
                                                                                                            ?.OT
                                                                                                )
                                                                                          )}
                                                                                    </div>
                                                                              </li>
                                                                        )}
                                                                        {totalPriceInfo
                                                                              ?.afC
                                                                              ?.TAF
                                                                              ?.YR && (
                                                                              <li className="price__item  d-flex align-items-center">
                                                                                    <div className="price__item--title w-75">
                                                                                          YR
                                                                                    </div>
                                                                                    <div className="price__item--amount">
                                                                                          ₹{" "}
                                                                                          {convertAmount(
                                                                                                convertFloat(
                                                                                                      totalPriceInfo
                                                                                                            ?.afC
                                                                                                            ?.TAF
                                                                                                            ?.YR
                                                                                                )
                                                                                          )}
                                                                                    </div>
                                                                              </li>
                                                                        )}
                                                                  </ul>
                                                            </PriceSummaryContent>
                                                            <div className="total d-flex align-items-center mb-0 border-top">
                                                                  <div className="total__title  w-75">
                                                                        Grand
                                                                        Total
                                                                  </div>
                                                                  <div className="total__price">
                                                                        ₹{" "}
                                                                        {convertAmount(
                                                                              convertFloat(
                                                                                    totalPriceInfo
                                                                                          ?.fC
                                                                                          ?.TF
                                                                              )
                                                                        )}
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </PriceSummary>
                                          </FlightReviewContent>
                                    </Col>
                              </Row>
                        ) : (
                              <div className="py-5 text-center">
                                    <Spinner />
                              </div>
                        )}
                  </DashboardContainer>
            </>
      );
};

export default FlightConfirmation;
