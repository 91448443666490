import React, { useEffect, useState } from "react";
import { DashboardCardTable } from "../../layouts/style";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { convertAmount, convertFloat } from "../../../hooks/CurrentData";
import { Link } from "react-router-dom";
import { VisbilityIcon } from "../../../icons";

const FlightTable = () => {
      const [loading, setLoading] = useState(false);
      const [bookings, setBookings] = useState([]);

      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight/bookings?order_type=AIR`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setBookings(response.data.bookings);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <DashboardCardTable>
                        <thead>
                              <tr>
                                    <th>S.N</th>
                                    <th>Name</th>
                                    <th>Booking Id</th>
                                    <th>Summary</th>
                                    <th>Amount (INR)</th>
                                    <th>Processed Remarks</th>
                                    <th>Remarks</th>
                              </tr>
                        </thead>
                        <tbody>
                              {!loading ? (
                                    bookings?.length > 0 ? (
                                          bookings?.map((item, index) => (
                                                <>
                                                      <tr key={index}>
                                                            <td>{++index}</td>
                                                            <td>
                                                                  {item?.name ||
                                                                        "-"}
                                                            </td>
                                                            <td>
                                                                  {item?.booking_id ||
                                                                        "-"}
                                                            </td>
                                                            <td>
                                                                  {item?.summary ||
                                                                        "-"}
                                                            </td>
                                                            <td>
                                                                  {item?.amount
                                                                        ? convertAmount(
                                                                                convertFloat(
                                                                                      item?.amount
                                                                                )
                                                                          )
                                                                        : "-"}
                                                            </td>
                                                            <td>
                                                                  {item?.status ||
                                                                        "-"}
                                                            </td>
                                                            <td>
                                                                  <Link
                                                                        to={`/flights/confirmation/${item?.booking_id}`}
                                                                        target="_blank"
                                                                  >
                                                                        <VisbilityIcon
                                                                              show={
                                                                                    true
                                                                              }
                                                                        />
                                                                  </Link>
                                                            </td>
                                                      </tr>
                                                </>
                                          ))
                                    ) : (
                                          <>
                                                <tr>
                                                      <td
                                                            colSpan="100%"
                                                            className="list-td text-center"
                                                      >
                                                            <p>No Bank Found</p>
                                                      </td>
                                                </tr>
                                          </>
                                    )
                              ) : (
                                    <>
                                          <tr>
                                                <td
                                                      colSpan="100%"
                                                      className="list-td text-center"
                                                >
                                                      <Spinner />
                                                </td>
                                          </tr>
                                    </>
                              )}
                        </tbody>
                  </DashboardCardTable>
            </>
      );
};

export default FlightTable;
