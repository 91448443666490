import React, { useEffect, useState } from "react";
import {
      PriceSummary,
      PriceTravellerItem,
} from "../../../../frontend/checkout/styles";
import { AdultIcon, ChildIcon, InfantIcon } from "../../../../icons";

import { convertAmount, convertFloat } from "../../../../hooks/CurrentData";
import PriceSummaryContent from "./PriceSummaryContent";
import PriceAirlineDetail from "./PriceAirlineDetail";
import PriceSummaryDetails from "./PriceSummaryDetails";

const PriceSummaryBox = ({
      priceInfo,
      totalAmount,
      setTotalAmount,
      searchQuery,
      serviceList,
      currentOption,
      tripInfos,
}) => {
      const [mealAmount, setMealAmount] = useState(0);
      const [baggageAmount, setBaggageAmount] = useState(0);
      const [otherServiceAmount, setOtherServiceAmount] = useState(0);
      const [seatDetailAmount, setSeatDetailAmount] = useState(0);
      const [totalServicesAmount, setTotalServiceAmount] = useState(0);

      const findingAmount = () => {
            let totalMealAmount = 0;
            let totalBaggageAmount = 0;
            let totalOtherAmount = 0;
            let totalSeatAmount = 0;

            if (serviceList) {
                  Object.entries(serviceList)?.forEach(
                        ([destination, passengers]) => {
                              Object.entries(passengers).forEach(
                                    ([passenger, services]) => {
                                          if (
                                                services?.Meal &&
                                                services.Meal?.amount
                                          ) {
                                                totalMealAmount += parseFloat(
                                                      services.Meal.amount
                                                );
                                          }
                                          if (
                                                services?.Baggage &&
                                                services?.Baggage?.amount
                                          ) {
                                                totalBaggageAmount +=
                                                      parseFloat(
                                                            services.Baggage
                                                                  .amount
                                                      );
                                          }
                                          if (
                                                services?.OtherService &&
                                                services?.OtherService?.amount
                                          ) {
                                                totalOtherAmount += parseFloat(
                                                      services.OtherService
                                                            .amount
                                                );
                                          }
                                          if (
                                                services?.Seat &&
                                                services?.Seat?.amount
                                          ) {
                                                totalSeatAmount += parseFloat(
                                                      services.Seat.amount
                                                );
                                          }
                                    }
                              );
                        }
                  );
            }
            setMealAmount(totalMealAmount);
            setBaggageAmount(totalBaggageAmount);
            setSeatDetailAmount(totalSeatAmount);
            setOtherServiceAmount(totalOtherAmount);
            setTotalServiceAmount(
                  totalMealAmount +
                        totalBaggageAmount +
                        totalSeatAmount +
                        totalOtherAmount
            );
      };

      useEffect(() => {
            const storedTotalAmount = localStorage.getItem("totalAmount");
            if (storedTotalAmount) {
                  setTotalAmount(storedTotalAmount);
            }
            findingAmount();
      }, [serviceList]);

      return (
            <>
                  {tripInfos && currentOption?.id === "review" && (
                        <>
                              <PriceAirlineDetail tripInfos={tripInfos} />
                        </>
                  )}
                  <PriceSummary>
                        <div className="price__box mb-4">
                              <div className="price__heading">
                                    <div className="price__title w-75">
                                          Fare Summary
                                    </div>
                                    <div className="price__people d-flex gap-3">
                                          <PriceTravellerItem>
                                                <AdultIcon color="rgba(33, 37, 41, 0.75)" />
                                                <div>
                                                      {
                                                            searchQuery?.paxInfo
                                                                  ?.ADULT
                                                      }
                                                </div>
                                          </PriceTravellerItem>
                                          <PriceTravellerItem>
                                                <ChildIcon color="rgba(33, 37, 41, 0.75)" />
                                                <div>
                                                      {
                                                            searchQuery?.paxInfo
                                                                  ?.CHILD
                                                      }
                                                </div>
                                          </PriceTravellerItem>
                                          <PriceTravellerItem>
                                                <InfantIcon color="rgba(33, 37, 41, 0.75)" />
                                                <div>
                                                      {
                                                            searchQuery?.paxInfo
                                                                  ?.INFANT
                                                      }
                                                </div>
                                          </PriceTravellerItem>
                                    </div>
                              </div>

                              {currentOption?.id === "travellers" && (
                                    <>
                                          <PriceSummaryDetails
                                                priceInfo={priceInfo}
                                                baggageAmount={baggageAmount}
                                                mealAmount={mealAmount}
                                                totalServicesAmount={
                                                      totalServicesAmount
                                                }
                                                border="0px"
                                                otherServiceAmount={
                                                      otherServiceAmount
                                                }
                                                seatDetailAmount={
                                                      seatDetailAmount
                                                }
                                          />
                                          <div className="total d-flex align-items-center mb-0">
                                                <div className="total__title  w-75">
                                                      Grand Total
                                                </div>
                                                <div className="total__price">
                                                      ₹{" "}
                                                      {convertAmount(
                                                            convertFloat(
                                                                  totalAmount
                                                            )
                                                      )}
                                                </div>
                                          </div>
                                    </>
                              )}

                              {currentOption?.id === "review" && (
                                    <PriceSummaryContent
                                          title="Grand Total"
                                          grandTotalStatus={false}
                                          totalServicesAmount={convertAmount(
                                                convertFloat(totalAmount)
                                          )}
                                    >
                                          <PriceSummaryDetails
                                                priceInfo={priceInfo}
                                                baggageAmount={baggageAmount}
                                                mealAmount={mealAmount}
                                                totalServicesAmount={
                                                      totalServicesAmount
                                                }
                                                border="0px"
                                                otherServiceAmount={
                                                      otherServiceAmount
                                                }
                                                seatDetailAmount={
                                                      seatDetailAmount
                                                }
                                          />
                                    </PriceSummaryContent>
                              )}
                        </div>
                        <div className="support">
                              Flight support : +91 8766601888
                        </div>
                  </PriceSummary>
            </>
      );
};

export default PriceSummaryBox;
