import React from "react";
import { FlightTable, ThirdContent } from "../../booking/style";
import {
      PriceAccordion,
      PriceAccordionHeader,
} from "../../../components/Frontend/checkout/PriceSummaryBox/style";
import { AccordionBody, AccordionHeader } from "react-bootstrap";
import { PassengerAccordion } from "./style";

const FlightConfirmPassenger = ({ travellerInfos }) => {
      const checkPassengerType = (value) => {
            if (value === "ADULT") {
                  return "Adult";
            } else if (value === "CHILD") {
                  return "Child";
            } else if (value === "INFANT") {
                  return "Infant";
            }
      };

      return (
            <>
                  <PassengerAccordion className="border-0 p-0" border={false}>
                        <PriceAccordionHeader display="block" className="p-0">
                              <AccordionHeader>
                                    Passenger (
                                    <span className="text-muted">{`${travellerInfos[0]?.fN} ${travellerInfos[0]?.lN}`}</span>
                                    )
                              </AccordionHeader>
                        </PriceAccordionHeader>
                        <AccordionBody>
                              <div className="card-third pt-0">
                                    <div className="card-body">
                                          <p className="third-title"></p>
                                          <div className="table-header">
                                                <FlightTable
                                                      length={
                                                            travellerInfos?.length
                                                      }
                                                >
                                                      <thead className="header-third">
                                                            <tr>
                                                                  <th>Sr.</th>
                                                                  <th className="xyz justify-content-start text-muted">
                                                                        Name,
                                                                        DOB &
                                                                        Passport
                                                                        & FF
                                                                  </th>
                                                                  <th className="text-muted">
                                                                        PNR,
                                                                        Ticket
                                                                        No. &
                                                                        Status
                                                                  </th>
                                                                  <th className="text-muted">
                                                                        Meal,
                                                                        Baggage,
                                                                        Seat &
                                                                        Other
                                                                        Preference
                                                                  </th>
                                                            </tr>
                                                      </thead>
                                                      <tbody>
                                                            {travellerInfos?.map(
                                                                  (
                                                                        item,
                                                                        index
                                                                  ) => (
                                                                        <tr
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <td>
                                                                                    {
                                                                                          ++index
                                                                                    }
                                                                              </td>
                                                                              <td>
                                                                                    <ThirdContent className="third-content">
                                                                                          <div className="passenger-details">
                                                                                                <strong>
                                                                                                      {`${item?.ti} ${item?.fN} ${item?.lN}`}{" "}
                                                                                                </strong>
                                                                                                <span>
                                                                                                      {checkPassengerType(
                                                                                                            item?.pt
                                                                                                      )}
                                                                                                </span>
                                                                                                {item?.pt !==
                                                                                                      "INFANT" && (
                                                                                                      <>
                                                                                                            <br />
                                                                                                            <div className="third-content__baggage">
                                                                                                                  <div className="third-content__pax text-muted">
                                                                                                                        {
                                                                                                                              item?.dob
                                                                                                                        }

                                                                                                                        ,
                                                                                                                        PP:{" "}
                                                                                                                        {
                                                                                                                              item?.pNum
                                                                                                                        }

                                                                                                                        ,
                                                                                                                        N:{" "}
                                                                                                                        {
                                                                                                                              item?.pNat
                                                                                                                        }
                                                                                                                  </div>
                                                                                                                  <div className="third-content__pax text-muted">
                                                                                                                        ID:{" "}
                                                                                                                        {
                                                                                                                              item?.pid
                                                                                                                        }

                                                                                                                        ,
                                                                                                                        ED:{" "}
                                                                                                                        {
                                                                                                                              item?.eD
                                                                                                                        }
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                      </>
                                                                                                )}
                                                                                          </div>
                                                                                    </ThirdContent>
                                                                              </td>
                                                                              <td>
                                                                                    <div className="ticket__number">
                                                                                          {item?.pnrDetails &&
                                                                                                Object.entries(
                                                                                                      item?.pnrDetails
                                                                                                ).map(
                                                                                                      (
                                                                                                            [
                                                                                                                  route,
                                                                                                                  pnr,
                                                                                                            ],
                                                                                                            index
                                                                                                      ) => (
                                                                                                            <div
                                                                                                                  key={
                                                                                                                        index
                                                                                                                  }
                                                                                                            >
                                                                                                                  <strong>
                                                                                                                        {
                                                                                                                              route
                                                                                                                        }
                                                                                                                  </strong>

                                                                                                                  :{" "}
                                                                                                                  {
                                                                                                                        pnr
                                                                                                                  }
                                                                                                            </div>
                                                                                                      )
                                                                                                )}
                                                                                    </div>
                                                                              </td>

                                                                              <td>
                                                                                    NA
                                                                              </td>
                                                                        </tr>
                                                                  )
                                                            )}
                                                      </tbody>
                                                </FlightTable>
                                          </div>
                                    </div>
                              </div>
                        </AccordionBody>
                  </PassengerAccordion>
            </>
      );
};

export default FlightConfirmPassenger;
