import { Modal, ModalBody, Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";

export const FlightTabs = styled(Tabs)`
      .nav-item {
            min-width: ${(props) =>
                  props.number
                        ? `calc(100% / ${props.number})`
                        : "calc(100% / 2)"};

            .nav-link {
                  min-width: 100%;
                  color: ${(props) => props.theme.black};
                  border: 0;

                  &.active,
                  &:hover {
                        color: ${(props) => props.theme.primary};
                        border: 0;
                        border-bottom: 2px solid
                              ${(props) => props.theme.primary};
                  }

                  &:focus-visible,
                  &:focus {
                        box-shadow: none;
                        border-top-color: transparent;
                        border-left-color: transparent;
                        border-right-color: transparent;
                  }
            }
      }
`;

export const FlightTab = styled(Tab)``;

export const FlightModal = styled(Modal)`
      .modal-body {
            position: relative;
      }
      .btn-close {
            position: absolute;
            cursor: pointer;
            top: 10px;
            right: 20px;
            z-index: 1;
            padding: 10px;
            border-radius: 50rem;
            border: 1px solid #fff;
            transition: all 0.3s ease-in;

            &:hover {
                  background-color: #f4f4f4;
                  border: 1px solid #e5e3e3;
            }

            &:focus {
                  box-shadow: none;
            }
      }
`;
