import React from "react";
import DashboardLayout from "../layouts";
import {
      DashboardCard,
      DashboardCardBody,
      DashboardCardHeader,
      DashboardCardTitle,
} from "../layouts/style";
import { MenuIcon } from "../layouts/menus/MenuItem/style";
import { DepositRequestIcon, DepositViewIcon, WalletIcon } from "../../icons";
import {
      MenuOptionIcon,
      MenuOptionItem,
      MenuOptionName,
      MenuOptions,
} from "./style";

const DepositManage = () => {
      return (
            <>
                  <DashboardLayout title="Deposit Manage">
                        <DashboardCard>
                              <DashboardCardHeader>
                                    <DashboardCardTitle>
                                          <MenuIcon>
                                                <DepositRequestIcon />
                                          </MenuIcon>
                                          Deposit Manage
                                    </DashboardCardTitle>
                              </DashboardCardHeader>
                              <DashboardCardBody padding="10px">
                                    <MenuOptions>
                                          <MenuOptionItem to="/view-deposit">
                                                <MenuOptionIcon color="#224082eb">
                                                      <WalletIcon
                                                            height="52px"
                                                            width="52px"
                                                      />
                                                </MenuOptionIcon>
                                                <MenuOptionName>
                                                      View Deposit Request
                                                </MenuOptionName>
                                          </MenuOptionItem>
                                          <MenuOptionItem to="/deposit-request">
                                                <MenuOptionIcon color="#f4a934eb">
                                                      <DepositViewIcon
                                                            height="52px"
                                                            width="52px"
                                                      />
                                                </MenuOptionIcon>
                                                <MenuOptionName color="#f4a934">
                                                      New Deposit Request
                                                </MenuOptionName>
                                          </MenuOptionItem>
                                    </MenuOptions>
                              </DashboardCardBody>
                        </DashboardCard>
                  </DashboardLayout>
            </>
      );
};

export default DepositManage;
