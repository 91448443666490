import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormImage, FormInput, FormSelect } from "../../forms";
import CommonButton from "../../../components/Common/CommonButton";
import axios from "axios";
import toast from "react-hot-toast";

const options = [
      {
            label: "Online Transfer",
            value: "Online Transfer",
      },
      {
            label: "Cheque Deposit",
            value: "Cheque Deposit",
      },
      {
            label: "Cash in Bank",
            value: "Cash in Bank",
      },
      {
            label: "Cash at Headoffice",
            value: "Cash at Headoffice",
      },
      {
            label: "Credit Memo",
            value: "Credit Memo",
      },
];

const DepositForm = () => {
      const [loading, setLoading] = useState(false);
      const [loadingData, setLoadingData] = useState([]);
      const [banks, setBanks] = useState([]);

      const [depositType, setDepositType] = useState();
      const [depositAmount, setDepositAmount] = useState(0);
      const [agentBankName, setAgentBankName] = useState("");
      const [agentDepositBranch, setAgentDepositBranch] = useState("");
      const [atlasBankName, setAtlasBankName] = useState("");
      const [atlasBankNumber, setAtlasBankNumber] = useState("");
      const [transitionId, setTransitionId] = useState("");
      const [mobileNumber, setMobileNumber] = useState("");
      const [chequeDrawBank, setChequeDrawBank] = useState("");
      const [chequeIssueDate, setChequeIssueDate] = useState("");
      const [chequeNumber, setChequeNumber] = useState("");
      const [file, setFile] = useState("");
      const [fileUrl, setFileUrl] = useState("");

      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoadingData(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/deposit-request/banks`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setBanks(response.data.banks);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  deposit_type: depositType,
                  deposit_amount: depositAmount,
                  agent_bank_name: agentBankName,
                  agent_deposit_branch: agentDepositBranch,
                  atlas_bank_name: atlasBankName,
                  atlas_bank_number: atlasBankNumber,
                  transaction_id: transitionId,
                  mobile_no: mobileNumber,
                  cheque_draw_bank: chequeDrawBank,
                  cheque_issue_date: chequeIssueDate,
                  cheque_number: chequeNumber,
                  image: file,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/deposit-request`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    "Content-Type": "multipart/form-data",
                              },
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.result === "success") {
                                    toast.success(toast.message);
                                    setDepositType("");
                                    setDepositAmount("");
                                    setAgentBankName("");
                                    setAgentDepositBranch("");
                                    setAtlasBankName('');
                                    setAtlasBankNumber("");
                                    setTransitionId('');
                                    setMobileNumber("");
                                    setChequeDrawBank("");
                                    setChequeIssueDate("");
                                    setChequeNumber('')
                                    setFile("");
                                    setFileUrl("");
                              }
                              if (response.data.result === "error") {
                                    if (response.data.message) {
                                          if (
                                                response.data.message
                                                      .deposit_type
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .deposit_type
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .deposit_amount
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .deposit_amount
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .agent_bank_name
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .agent_bank_name
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .agent_deposit_branch
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .agent_deposit_branch
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .atlas_bank_name
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .atlas_bank_name
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .atlas_bank_number
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .atlas_bank_number
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .transaction_id
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .transaction_id
                                                );
                                          }
                                          if (response.data.message.mobile_no) {
                                                toast.error(
                                                      response.data.message
                                                            .mobile_no
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .cheque_draw_bank
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .cheque_draw_bank
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .cheque_issue_date
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .cheque_issue_date
                                                );
                                          }
                                          if (
                                                response.data.message
                                                      .cheque_number
                                          ) {
                                                toast.error(
                                                      response.data.message
                                                            .cheque_number
                                                );
                                          }
                                          if (response.data.message.image) {
                                                toast.error(
                                                      response.data.message
                                                            .image
                                                );
                                          }
                                    }
                              }
                              setLoading(false);
                        }, 3000);
                  })
                  .catch((error) => {
                        setLoading(false);
                        console.log(error.message);
                  });
      };

      useEffect(() => {
            const currentBank = banks?.find(
                  (item) => item?.value === atlasBankName
            );
            console.log(currentBank);
            console.log(atlasBankName);
            if (currentBank) {
                  setAtlasBankNumber(currentBank?.account_number);
            } else {
                  setAtlasBankNumber("");
            }
      }, [atlasBankName]);

      return (
            <>
                  <Form onSubmit={handleSubmit}>
                        <Row className="g-3">
                              <FormSelect
                                    title="Deposit Type"
                                    required={true}
                                    options={options}
                                    selected={depositType}
                                    setData={setDepositType}
                              />
                              <FormInput
                                    title="Deposit Amount"
                                    required={true}
                                    value={depositAmount}
                                    handleChange={setDepositAmount}
                              />
                              <FormInput
                                    title="Agent Bank Name"
                                    value={agentBankName}
                                    disabled={
                                          depositType === "Cheque Deposit" ||
                                          depositType === "Cash in Bank" ||
                                          depositType ===
                                                "Cash at Headoffice" ||
                                          depositType === "Credit Memo"
                                    }
                                    handleChange={setAgentBankName}
                              />
                              <FormInput
                                    title="Agent Deposit Branch"
                                    value={agentDepositBranch}
                                    disabled={
                                          depositType === "Cheque Deposit" ||
                                          depositType === "Cash in Bank" ||
                                          depositType ===
                                                "Cash at Headoffice" ||
                                          depositType === "Credit Memo"
                                    }
                                    handleChange={setAgentDepositBranch}
                              />
                              <FormSelect
                                    title="Atlas Bank Name"
                                    options={banks}
                                    disabled={
                                          depositType ===
                                                "Cash at Headoffice" ||
                                          depositType === "Credit Memo"
                                    }
                                    selected={atlasBankName}
                                    setData={setAtlasBankName}
                              />
                              <FormInput
                                    title="Atlas Bank Number"
                                    value={atlasBankNumber}
                                    disabled={
                                          depositType ===
                                                "Cash at Headoffice" ||
                                          depositType === "Credit Memo" ||
                                          atlasBankNumber
                                    }
                                    handleChange={setAtlasBankNumber}
                              />
                              <FormInput
                                    title="Transaction Id"
                                    value={transitionId}
                                    handleChange={setTransitionId}
                              />
                              <FormInput
                                    title="Mobile No."
                                    value={mobileNumber}
                                    handleChange={setMobileNumber}
                                    disabled={
                                          depositType === "Cash at Headoffice"
                                    }
                              />
                              <FormInput
                                    classes={4}
                                    value={chequeDrawBank}
                                    disabled={
                                          depositType === "Online Transfer" ||
                                          depositType === "Cash in Bank" ||
                                          depositType ===
                                                "Cash at Headoffice" ||
                                          depositType === "Credit Memo"
                                    }
                                    title="Cheque Draw on Bank"
                                    handleChange={setChequeDrawBank}
                              />
                              <FormInput
                                    classes={4}
                                    value={chequeIssueDate}
                                    type="date"
                                    disabled={
                                          depositType === "Online Transfer" ||
                                          depositType === "Cash in Bank" ||
                                          depositType ===
                                                "Cash at Headoffice" ||
                                          depositType === "Credit Memo"
                                    }
                                    title="Cheque Issue Date"
                                    handleChange={setChequeIssueDate}
                              />
                              <FormInput
                                    classes={4}
                                    value={chequeNumber}
                                    disabled={
                                          depositType === "Online Transfer" ||
                                          depositType === "Cash in Bank" ||
                                          depositType ===
                                                "Cash at Headoffice" ||
                                          depositType === "Credit Memo"
                                    }
                                    title="Cheque Number"
                                    handleChange={setChequeNumber}
                              />
                              <FormImage
                                    imageUrl={fileUrl}
                                    setImage={setFile}
                                    setImageUrl={setFileUrl}
                              />

                              <Col lg={12}>
                                    <CommonButton
                                          title="SUBMIT"
                                          type="submit"
                                          button={true}
                                          loading={loading}
                                    />
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default DepositForm;
