import React from "react";
import { PriceSummary } from "../../../../../frontend/checkout/styles";
import { FlightDetailInfo } from "../../../../Dashboard/FlightDetails/style";
import { FlightReviewTable } from "../../../flight_reviews/FlightReviewDetail/style";
import { BaggageIcon, MealIcon, ServiceIcon } from "../../../../../icons";
import { PriceAirlineDetailContainer } from "../PriceAirlineDetail/style";

const PriceAddOn = () => {
      const travellerDetails = JSON.parse(localStorage.getItem("travellers"));
      const services = JSON.parse(localStorage.getItem("services"));

      return (
            <>
                  {services && (
                        <PriceSummary>
                              <div className="price__box mb-3">
                                    <PriceAirlineDetailContainer className="pb-0 border-top-0 pt-0 mt-0">
                                          <div className="price__heading">
                                                <div className="price__title">
                                                      Add Ons{" "}
                                                </div>
                                                <div className="price__modal">
                                                      View Details
                                                </div>
                                          </div>
                                          <FlightDetailInfo
                                                className="mt-0 pb-0 mb-0 pt-0"
                                                border="0"
                                          >
                                                {travellerDetails?.length > 0 &&
                                                      travellerDetails?.map(
                                                            (item, index) => (
                                                                  <FlightReviewTable
                                                                        border={
                                                                              false
                                                                        }
                                                                        className="pt-0 mt-0 pb-0 mb-0"
                                                                  >
                                                                        {/* <div>
                                                      {Object.keys(
                                                            services
                                                      )?.map((service) => (
                                                            <>
                                                                  <li>
                                                                        <strong>
                                                                              {
                                                                                    service
                                                                              }
                                                                        </strong>
                                                                        :{" "}
                                                                        <span className="text-muted">
                                                                              {
                                                                                    services[
                                                                                          service
                                                                                    ][
                                                                                          item
                                                                                                ?.ptName
                                                                                    ]
                                                                                          ?.Seat
                                                                                          ?.code
                                                                              }
                                                                        </span>
                                                                  </li>
                                                            </>
                                                      ))}
                                                </div> */}
                                                                        <div>
                                                                              {Object.keys(
                                                                                    services
                                                                              )?.map(
                                                                                    (
                                                                                          service
                                                                                    ) => (
                                                                                          <>
                                                                                                <li>
                                                                                                      {services[
                                                                                                            service
                                                                                                      ][
                                                                                                            item
                                                                                                                  ?.ptName
                                                                                                      ]
                                                                                                            ?.Meal
                                                                                                            ?.name && (
                                                                                                            <div className="d-flex align-items-center w-100">
                                                                                                                  <MealIcon
                                                                                                                        width="20px"
                                                                                                                        height="20px"
                                                                                                                  />
                                                                                                                  {
                                                                                                                        service
                                                                                                                  }
                                                                                                                  {
                                                                                                                        ": "
                                                                                                                  }
                                                                                                                  {
                                                                                                                        services[
                                                                                                                              service
                                                                                                                        ][
                                                                                                                              item
                                                                                                                                    ?.ptName
                                                                                                                        ]
                                                                                                                              ?.Meal
                                                                                                                              ?.name
                                                                                                                  }
                                                                                                            </div>
                                                                                                      )}
                                                                                                      {services[
                                                                                                            service
                                                                                                      ][
                                                                                                            item
                                                                                                                  ?.ptName
                                                                                                      ]
                                                                                                            ?.Baggage
                                                                                                            ?.name && (
                                                                                                            <div className="d-flex align-items-center w-100">
                                                                                                                  <BaggageIcon
                                                                                                                        width="20px"
                                                                                                                        height="20px"
                                                                                                                  />
                                                                                                                  {
                                                                                                                        service
                                                                                                                  }
                                                                                                                  {
                                                                                                                        ": "
                                                                                                                  }
                                                                                                                  {
                                                                                                                        services[
                                                                                                                              service
                                                                                                                        ][
                                                                                                                              item
                                                                                                                                    ?.ptName
                                                                                                                        ]
                                                                                                                              ?.Baggage
                                                                                                                              ?.name
                                                                                                                  }
                                                                                                            </div>
                                                                                                      )}
                                                                                                      {services[
                                                                                                            service
                                                                                                      ][
                                                                                                            item
                                                                                                                  ?.ptName
                                                                                                      ]
                                                                                                            ?.Baggage
                                                                                                            ?.name && (
                                                                                                            <div className="d-flex align-items-center w-100">
                                                                                                                  <ServiceIcon
                                                                                                                        width="20px"
                                                                                                                        height="20px"
                                                                                                                  />
                                                                                                                  {
                                                                                                                        service
                                                                                                                  }
                                                                                                                  {
                                                                                                                        ": "
                                                                                                                  }
                                                                                                                  {
                                                                                                                        services[
                                                                                                                              service
                                                                                                                        ][
                                                                                                                              item
                                                                                                                                    ?.ptName
                                                                                                                        ]
                                                                                                                              ?.Baggage
                                                                                                                              ?.name
                                                                                                                  }
                                                                                                            </div>
                                                                                                      )}
                                                                                                </li>
                                                                                          </>
                                                                                    )
                                                                              )}
                                                                        </div>
                                                                  </FlightReviewTable>
                                                            )
                                                      )}
                                          </FlightDetailInfo>
                                    </PriceAirlineDetailContainer>
                              </div>
                        </PriceSummary>
                  )}
            </>
      );
};

export default PriceAddOn;
