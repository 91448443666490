import React, { useCallback, useEffect, useState } from "react";
import DepartureList from "../../../Dashboard/Departure";
import FilterDeparture from "../FilterDeparture";
import FilterAirlines from "../FilterAirlines";
import DashboardList from "../../../Dashboard/DashboardList";
import FilterReturn from "../FilterReturn";
import InputSlider from "../../../Common/InputSlider";

const OnewayFilter = ({
      flights,
      searchDetail,
      searchDetails,
      setFilteredFlights,
      lists,
      arrivalLists,
      stopLists,
      returnStatus,
      returnAirlines,
      setReturnAirlines,
}) => {
      const [airlinesList, setAirlinesList] = useState([]);
      const [departures, setDepartures] = useState([]);
      const [arrivals, setArrivals] = useState([]);
      const [stops, setStops] = useState([]);
      const [range, setRange] = useState([0, 0]);

      const findItems = useCallback((items) => {
            let flightItems = items.find((item) => item?.fD);
            return flightItems?.fD;
      }, []);

      const getUniqueAirlinesFromFlights = (flights) => {
            const specialReturnFlights = flights
                  .map((flight) => {
                        // Filter totalPriceList for "SPECIAL_RETURN"
                        const matchedPrices = flight.totalPriceList.filter(
                              (item) => item.fareIdentifier === "SPECIAL_RETURN"
                        );

                        // Only return flights that have matched prices
                        return {
                              ...flight, // Keep other flight details
                              totalPriceList: matchedPrices, // Update totalPriceList with matched prices
                        };
                  })
                  .filter((flight) => flight.totalPriceList.length > 0);

            const uniqueAirlinesMap = {};

            specialReturnFlights.forEach((flight) => {
                  // Check if sI exists and is an array
                  if (Array.isArray(flight.sI)) {
                        flight.sI.forEach((airlineDetail) => {
                              const airlineCode = airlineDetail.fD.aI.code; // Get the airline code
                              const airlineName = airlineDetail.fD.aI.name; // Get the airline name

                              // Check if the airline code is not already in the map
                              if (!uniqueAirlinesMap[airlineCode]) {
                                    // Add the airline to the map
                                    uniqueAirlinesMap[airlineCode] = {
                                          code: airlineCode,
                                          name: airlineName,
                                          checked: false,
                                    };
                              }
                        });
                  }
            });

            // Convert the map values to an array of unique airlines
            const uniqueAirlines = Object.values(uniqueAirlinesMap);

            return uniqueAirlines;
      };

      // Initialize airlines
      useEffect(() => {
            const airlineMap =
                  flights &&
                  flights.reduce((acc, flight) => {
                        let newItem = findItems(flight?.sI);
                        if (newItem) {
                              const code = newItem.aI.code;
                              if (!acc[code]) {
                                    acc[code] = {
                                          ...newItem,
                                          totalFlights: 0,
                                          checked: false,
                                    };
                              }
                              acc[code].totalFlights += 1;
                        }
                        return acc;
                  }, {});
            if (airlineMap) {
                  setAirlinesList(Object.values(airlineMap));
            }
            setReturnAirlines(getUniqueAirlinesFromFlights(flights));
      }, [flights, findItems]);

      // Initialize departures, arrivals, and stops
      useEffect(() => {
            setDepartures(lists[0]?.items || []);
            setArrivals(arrivalLists[0]?.items || []);
            setStops(stopLists[0]?.items || []); // Initialize stops
      }, [lists, arrivalLists, stopLists]);

      // Handle click events
      const handleReturnAirlineClick = (index) => {
            setReturnAirlines((prevList) =>
                  prevList.map((item, i) =>
                        i === index
                              ? { ...item, checked: !item.checked }
                              : { ...item, checked: false }
                  )
            );
      };

      const handleAirlineClick = (index) => {
            setAirlinesList((prevList) =>
                  prevList.map((airline, i) =>
                        i === index
                              ? { ...airline, checked: !airline.checked }
                              : airline
                  )
            );
      };

      const handleDepartureClick = (index) => {
            setDepartures((prevList) =>
                  prevList.map((departure, i) =>
                        i === index
                              ? { ...departure, checked: !departure.checked }
                              : departure
                  )
            );
      };

      const handleStopClick = (index) => {
            setStops((prevList) =>
                  prevList.map((stop, i) =>
                        i === index ? { ...stop, checked: !stop.checked } : stop
                  )
            );
      };

      const handleArrivalClick = (index) => {
            setArrivals((prevList) =>
                  prevList.map((arrival, i) =>
                        i === index
                              ? { ...arrival, checked: !arrival.checked }
                              : arrival
                  )
            );
      };

      // Calculate stop counts
      const calculateStopCounts = (flightsList) => {
            let stopCounts = { 0: 0, 1: 0, 2: 0 };

            flightsList &&
                  flightsList.forEach((flight) => {
                        const totalStops = flight.sI.length - 1;
                        if (totalStops === 0) {
                              stopCounts[0]++;
                        } else if (totalStops === 1) {
                              stopCounts[1]++;
                        } else {
                              stopCounts[2]++;
                        }
                  });

            return stopCounts;
      };

      const minPrice = Math.min(
            ...flights
                  ?.map((flight) => {
                        // Extract the minimum price for each flight
                        return Math.min(
                              ...flight.totalPriceList
                                    .filter(
                                          (price) =>
                                                price.fd.ADULT.cc ===
                                                searchDetail?.cabinClass // Filter based on cabin class
                                    )
                                    .map(
                                          (price) =>
                                                price?.fd?.ADULT?.fC?.TF ||
                                                Infinity // Map to TF or Infinity if not available
                                    )
                        );
                  })
                  .filter((price) => price !== Infinity) // Filter out invalid prices
      );

      const maxPrice = Math.max(
            ...flights
                  ?.map((flight) => {
                        // Extract the maximum price for each flight
                        return Math.max(
                              ...flight.totalPriceList
                                    .filter(
                                          (price) =>
                                                price.fd.ADULT.cc ===
                                                searchDetail?.cabinClass // Filter based on cabin class
                                    )
                                    .map(
                                          (price) =>
                                                price?.fd?.ADULT?.fC?.TF ||
                                                -Infinity // Map to TF or -Infinity if not available
                                    )
                        );
                  })
                  .filter((price) => price !== -Infinity) // Filter out invalid prices
      );

      // Filter flights and update stops
      useEffect(() => {
            const filterFlightsByReturnSpecial = (flights, checkedItems) => {
                  if (checkedItems.length === 0) return flights;
                  const specialReturnFlights = flights
                        .map((flight) => {
                              // Filter totalPriceList for "SPECIAL_RETURN"
                              const matchedPrices =
                                    flight.totalPriceList.filter(
                                          (item) =>
                                                item.fareIdentifier ===
                                                "SPECIAL_RETURN"
                                    );

                              // Only return flights that have matched prices
                              return {
                                    ...flight, // Keep other flight details
                                    totalPriceList: matchedPrices, // Update totalPriceList with matched prices
                              };
                        })
                        .filter((flight) => flight.totalPriceList.length > 0); // Keep flights with at least one matched price
                  const newData = specialReturnFlights.filter((item) => {
                        return item.sI.some((segment) => {
                              return checkedItems.some((code) => {
                                    if (code === segment?.fD?.aI?.code)
                                          return true;
                                    return false;
                              });
                        });
                  });

                  return newData;
            };

            // Helper function to filter flights by time range
            const filterFlightsByTimeRange = (
                  flights,
                  checkedItems,
                  isDeparture
            ) => {
                  if (checkedItems.length === 0) return flights;

                  return flights.filter((flight) => {
                        return flight.sI.some((segment) => {
                              const dateTime = isDeparture
                                    ? new Date(segment.dt)
                                    : new Date(segment.at);
                              const hours = dateTime.getHours();

                              return checkedItems.some((timeSlot) => {
                                    if (timeSlot === "00 - 06" && hours < 6)
                                          return true;
                                    if (
                                          timeSlot === "06 - 12" &&
                                          hours >= 6 &&
                                          hours < 12
                                    )
                                          return true;
                                    if (
                                          timeSlot === "12 - 18" &&
                                          hours >= 12 &&
                                          hours < 18
                                    )
                                          return true;
                                    if (timeSlot === "18 - 00" && hours >= 18)
                                          return true;
                                    return false;
                              });
                        });
                  });
            };

            // Get checked departures and arrivals
            const checkedReturnAirline = returnAirlines
                  ?.filter((item) => item.checked)
                  .map((item) => item.code);

            const checkedDeparture = departures
                  .filter((item) => item.checked)
                  .map((item) => item.title);
            const checkedArrival = arrivals
                  .filter((item) => item.checked)
                  .map((item) => item.title);

            // Filter flights by departure times
            let filteredFlights = flights;
            if (checkedReturnAirline.length > 0) {
                  filteredFlights = filterFlightsByReturnSpecial(
                        filteredFlights,
                        checkedReturnAirline
                  );
            }

            if (checkedDeparture.length > 0) {
                  filteredFlights = filterFlightsByTimeRange(
                        filteredFlights,
                        checkedDeparture,
                        true
                  );
            }

            // Filter flights by arrival times
            if (checkedArrival.length > 0) {
                  filteredFlights = filterFlightsByTimeRange(
                        filteredFlights,
                        checkedArrival,
                        false
                  );
            }

            // Handle airline filter
            const checkedAirlines = airlinesList
                  .filter((airline) => airline.checked)
                  .map((airline) => airline.aI.code);
            if (checkedAirlines.length > 0) {
                  filteredFlights = filteredFlights.filter((flight) =>
                        flight.sI.some((segment) =>
                              checkedAirlines.includes(segment.fD.aI.code)
                        )
                  );
            }

            // Calculate stop counts and update stop values
            const stopCounts = calculateStopCounts(filteredFlights);
            setStops((prevStops) => {
                  const updatedStops = prevStops.map((stop) => ({
                        ...stop,
                        value:
                              stop.id === 1
                                    ? stopCounts[0]
                                    : stop.id === 2
                                    ? stopCounts[1]
                                    : stopCounts[2],
                  }));

                  return JSON.stringify(updatedStops) !==
                        JSON.stringify(prevStops)
                        ? updatedStops
                        : prevStops;
            });

            // Handle stop filter
            const checkedStops = stops
                  .filter((item) => item.checked)
                  .map((item) => item.title);
            if (checkedStops.length > 0) {
                  filteredFlights = filteredFlights.filter((flight) => {
                        const totalStops = flight.sI.length - 1;
                        return checkedStops.some((stopType) => {
                              if (stopType === "Non-stop" && totalStops === 0)
                                    return true;
                              if (stopType === "1 Stop" && totalStops === 1)
                                    return true;
                              if (stopType === "1+ Stops" && totalStops >= 2)
                                    return true;
                              return false;
                        });
                  });
            }

            filteredFlights = filteredFlights?.filter((flight) => {
                  const flightPrice = Math.min(
                        ...flight.totalPriceList
                              .filter(
                                    (price) =>
                                          price.fd.ADULT.cc ===
                                          searchDetail?.cabinClass // Filter based on cabin class
                              )
                              .map(
                                    (price) =>
                                          price?.fd?.ADULT?.fC?.TF || Infinity
                              ) // Get the price
                  );
                  return flightPrice >= range[0] && flightPrice <= range[1]; // Filter by range
            });

            if (
                  (minPrice === range[0] || minPrice !== range[0]) &&
                  (maxPrice === range[1] || maxPrice !== range[1])
            ) {
                  setFilteredFlights(filteredFlights);
            } else {
                  // Update filtered flights
                  setFilteredFlights(
                        checkedAirlines.length === 0 &&
                              checkedDeparture.length === 0 &&
                              checkedArrival.length === 0 &&
                              checkedStops.length === 0 &&
                              checkedReturnAirline.length === 0
                              ? flights
                              : filteredFlights
                  );
            }
      }, [
            airlinesList,
            departures,
            arrivals,
            flights,
            stops,
            returnAirlines,
            range,
      ]);

      return (
            <>
                  <InputSlider
                        range={range}
                        setRange={setRange}
                        flights={flights}
                        searchDetail={searchDetail}
                  />
                  <DashboardList
                        lists={stopLists}
                        stops={stops}
                        handleClick={handleStopClick}
                  />
                  {returnStatus && (
                        <FilterReturn
                              returnAirlines={returnAirlines}
                              handleClick={handleReturnAirlineClick}
                        />
                  )}
                  <DepartureList
                        lists={lists}
                        departures={departures}
                        searchDetails={searchDetails}
                        handleClick={handleDepartureClick}
                  />
                  <FilterDeparture
                        lists={arrivalLists}
                        searchDetails={searchDetails}
                        arrivals={arrivals}
                        handleClick={handleArrivalClick}
                  />
                  <FilterAirlines
                        airlinesList={airlinesList}
                        handleClick={handleAirlineClick}
                  />
            </>
      );
};

export default OnewayFilter;
