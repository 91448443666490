import styled from "styled-components";
import { Tab, Tabs } from "react-bootstrap";

export const RountripFilterContainer = styled(Tabs)`
      .nav-item {
            width: 50%;
            text-align: center;
      }
      .nav-link {
            border: 0;
            width: 100%;
            padding-top: 0;
            color: var(--bs-nav-tabs-link-active-color);
            padding-bottom: 10px;
            cursor: pointer;
            font-weight: ${(props) => props.status && "600"};
            transition: all 0.3s ease-in-out;

            &:hover,
            &.active {
                  border-bottom: 2px solid ${(props) => props.theme.primary};
                  color: ${(props) => props.theme.primary};
            }
      }
`;

export const RoundtripFilterItem = styled(Tab)`
      &.nav-item {
            &.nav-link {
                  border-bottom: 2px solid
                        ${(props) =>
                              props.status ? props.theme.primary : "#f4f4f4"};
                  padding-bottom: 10px;
                  margin-bottom: 20px;
                  text-align: center;
                  cursor: pointer;
                  color: ${(props) => props.status && props.theme.primary};
                  font-weight: ${(props) => props.status && "600"};
                  transition: all 0.3s ease-in-out;

                  &:hover {
                        border-bottom: 2px solid
                              ${(props) => props.theme.primary};
                  }
            }
      }
`;
