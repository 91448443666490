import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { FlightDetailContainer } from "../../../../Dashboard/FlightDetails/style";
import {
      FlightReviewContent,
      FlightReviewHeader,
      FlightReviewInfo,
} from "../style";
import { TravellerModal } from "./style";

const TravellerReviewDetail = () => {
      const [show, setShow] = useState(false);
      const gstDetails = JSON.parse(localStorage.getItem("gstDetails"));
      const contactDetails = JSON.parse(localStorage.getItem("contactDetails"));

      return (
            <>
                  <TravellerModal onClick={() => setShow(true)}>
                        View Details
                  </TravellerModal>
                  <Modal show={show} size="lg" onHide={() => setShow(false)}>
                        <ModalHeader closeButton>Traveller Details</ModalHeader>
                        <ModalBody>
                              <FlightDetailContainer>
                                    <FlightReviewContent>
                                          <FlightReviewHeader>
                                                Contact Details
                                          </FlightReviewHeader>

                                          <FlightReviewInfo>
                                                <ul>
                                                      <li>
                                                            Email:{" "}
                                                            {
                                                                  contactDetails?.emailAddress
                                                            }
                                                      </li>
                                                      <li>
                                                            Mobile:{" "}
                                                            {contactDetails &&
                                                                  `${contactDetails?.countryCode}-${contactDetails?.phoneNumber}`}
                                                      </li>
                                                </ul>
                                          </FlightReviewInfo>
                                    </FlightReviewContent>
                              </FlightDetailContainer>
                              {gstDetails && (
                                    <FlightDetailContainer>
                                          <FlightReviewContent>
                                                <FlightReviewHeader>
                                                      GST Details
                                                </FlightReviewHeader>
                                                <FlightReviewInfo>
                                                      <ul>
                                                            <li>
                                                                  Reg. Number:{" "}
                                                                  {
                                                                        gstDetails?.registrationNumber
                                                                  }
                                                            </li>
                                                            <li>
                                                                  Company Name:{" "}
                                                                  {`${gstDetails?.companyName}`}
                                                            </li>
                                                      </ul>
                                                </FlightReviewInfo>
                                          </FlightReviewContent>
                                    </FlightDetailContainer>
                              )}
                        </ModalBody>
                  </Modal>
            </>
      );
};

export default TravellerReviewDetail;
