import styled from "styled-components";

export const DashboardContainer = styled.div`
      padding: 0 3rem;
      background-color: #f4f4f4;
      padding-top: 120px;
      padding-bottom: 60px;
      gap: 20px;
      min-height: 900px;
      width: 100%;
`;

export const DashboardBreadcrumb = styled.div`
      background-color: ${(props) => props.theme.primary};
      padding: 38px 0 30px;
      width: 100%;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
`;

export const BreadcrumbContainer = styled.div`
      padding-left: 334px;

      .breadcrumb {
            margin: 0;

            a {
                  color: #ccc;
                  font-size: 14px;
            }
      }

      h2 {
            margin: 0;
            font-size: 24px;
            font-weight: 600;
            color: ${(props) => props.theme.white};
      }
`;

export const DashboardContent = styled.div`
      padding: 0 10px;
      display: flex;
      gap: 20px;
`;

export const DashboardSidebar = styled.div`
      border: 1px solid #e2e2e2;
      width: 300px;
      min-width: 300px;
      margin-top: -98px;
      border-radius: 5px;
      background-color: ${(props) => props.theme.primary};
`;

export const ProfileCard = styled.div`
      padding: 10px;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      background-color: ${(props) => props.theme.white};
`;

export const ProfileImage = styled.div`
      width: 136px;
      height: 136px;
      margin: auto;
      border: 1px solid ${(props) => props.theme.primary};
      padding: 10px;
      border-radius: 50rem;

      img {
            height: 100%;
            width: 100%;
            border-radius: 50rem;
      }
`;

export const ProfileName = styled.div`
      margin-top: 10px;
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
`;

export const DashboardCard = styled.div`
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      width: calc(100%);
      margin-top: 20px;
      border-radius: 5px;
      background-color: ${(props) => props.theme.white};
`;

export const DashboardCardHeader = styled.div`
      margin: 10px;
      margin-top: 0;
      border-bottom: 1px solid #e8e8e8;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &::after {
            position: absolute;
            bottom: -4px;
            left: 32px;
            width: 8px;
            height: 8px;
            border: 1px solid #e8e8e8;
            border-width: 1px 1px 0 0;
            content: "";
            background: #fff;
            transform: rotate(132deg);
            -webkit-transform: rotate(132deg);
      }
`;

export const DashboardCardTitle = styled.div`
      display: flex;
      align-items: center;
`;

export const DashboardCardNote = styled.p`
      padding: 0 10px;
      margin: 0;
      font-size: 14px;
      margin-bottom: 10px;
`;

export const DashboardCardBody = styled.div`
      padding: ${(props) => props.padding};
      margin: ${(props) => props.margin};
      background-color: ${(props) => props.backgroundColor};

      .form-label {
            font-size: 14px;
            color: #929292;
            margin-bottom: 0.25rem;
      }

      input,
      select {
            border-radius: 5px;
            color: #929292;
      }
`;

export const DashboardBankTable = styled.table`
      width: 100%;

      thead {
            width: 100%;

            tr {
                  background-color: ${(props) => props.theme.primary};
            }

            th {
                  color: ${(props) => props.theme.white};
                  font-weight: 500;
                  font-size: 12px;
                  text-transform: uppercase;
                  /* font-size: 11px; */
                  height: 40px;
                  padding: 2px 8px;
                  font-weight: normal;
                  border-right: 1px solid rgba(255, 255, 255, 0.1);
                  text-align: center;
            }
      }

      tbody {
            width: 100%;

            tr {
                  background-color: #f9f9f9;

                  &:nth-child(4n + 1) {
                        background: #fff;
                  }

                  &:nth-child(4n + 2) {
                        background: #fff;
                  }

                  td {
                        border-bottom: none;
                        padding: 4px 8px;
                        font-size: 12px;
                        border-right: none;
                        text-align: center;
                        line-height: 19px;

                        &.list-td {
                              position: relative;
                              border-bottom: 1px solid #dedede !important;
                              padding: 3px 8px;
                              text-align: left;

                              p {
                                    margin: 0;
                                    color: #f4a934;
                                    font-size: 13px;
                              }
                        }
                  }
            }
      }
`;

export const DashboardCardTable = styled.table`
      width: 100%;

      thead {
            width: 100%;

            tr {
                  background-color: ${(props) => props.theme.primary};
            }

            th {
                  color: ${(props) => props.theme.white};
                  font-weight: 500;
                  font-size: 12px;
                  text-transform: uppercase;
                  height: 40px;
                  padding: 2px 8px;
                  font-weight: normal;
                  border-right: 1px solid rgba(255, 255, 255, 0.1);
                  text-align: center;
            }
      }

      tbody {
            width: 100%;

            tr {
                  background-color: #f9f9f9;
                  border-right: 1px solid rgba(255, 255, 255, 0.1);

                  &:nth-child(4n + 1) {
                        background: #fff;
                  }

                  /* &:nth-child(4n + 2) {
                        background: #fff;
                  } */

                  td {
                        border-bottom: 1px solid #dedede;
                        padding: 4px 8px;
                        font-size: 12px;
                        border-right: 1px solid rgba(0, 0, 0, 0.07);
                        text-align: center;
                        line-height: 19px;

                        &.list-td {
                              position: relative;
                              border-bottom: 1px solid #dedede !important;
                              padding: 3px 8px;
                              text-align: left;

                              p {
                                    margin: 0;
                                    color: #f4a934;
                                    font-size: 13px;
                              }
                        }
                  }
            }
      }
`;
