import styled from "styled-components";

export const MenuContainer = styled.div`
      ul {
            padding: 0;
            list-style: none;
            margin: 0;

            li {
                  a,
                  button {
                        border-bottom: 0.5px dashed
                              ${(props) => props.theme.white};
                        transition: all 0.3s ease-in-out;
                        background-color: ${(props) => props.theme.primary};
                        display: flex;
                        gap: 10px;
                        width: 100%;
                        font-size: 14px;
                        align-items: center;
                        color: ${(props) => props.theme.white};

                        span {
                              font-size: 20px;
                        }

                        &:hover,
                        &.active {
                              background-color: #f4a934;
                        }
                  }

                  &:last-child {
                        border-bottom-left-radius: 5px;
                        border-bottom: 0;
                        border-bottom-right-radius: 5px;
                  }
            }
      }
`;
