import React from "react";
import { Col, FormControl, FormGroup, FormLabel } from "react-bootstrap";

const FormInput = ({
      title,
      type,
      required,
      handleChange,
      classes,
      disabled,
      value,
}) => {
      return (
            <>
                  <Col lg={classes || 3}>
                        <FormGroup>
                              <FormLabel>
                                    {title}
                                    {required && (
                                          <span className="text-danger">
                                                {" "}
                                                *
                                          </span>
                                    )}
                              </FormLabel>
                              <FormControl
                                    type={type || "text"}
                                    required={required}
                                    disabled={disabled}
                                    value={value}
                                    onChange={(event) =>
                                          handleChange(event.target.value)
                                    }
                              />
                        </FormGroup>
                  </Col>
            </>
      );
};

export default FormInput;
